import * as Yup from "yup";
import {useFormik} from "formik";
import {useRouter} from "src/hooks/use-router";
import {useAuth} from "src/hooks/use-auth";
import {Stack} from "@mui/system";
import Typography from "@mui/material/Typography";
import {Link, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {useTranslation} from "react-i18next";
import {useSettings} from "src/hooks/use-settings";
import {paths} from "src/pages/paths";
import {useLocation} from "react-router-dom";

const initialValues = {
  email: "",
  password: "",
  submit: null,
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  password: Yup.string().max(255).required("Password is required"),
});

export const Login = () => {
  const {t} = useTranslation();
  const router = useRouter();
  const settings = useSettings();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const redirect = queryParams.get("r")
    ? queryParams.get("r")
    : paths.dashboard.openSurveys;

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, helpers) => {
      setLoading(true);
      auth
        .signIn(values.email, values.password)
        .then(response => {
          router.push(redirect);
          setLoading(false);
          helpers.setSubmitting(false);
        })
        .catch(err => {
          setLoading(false);
          helpers.setSubmitting(false);
          settings.showAlert("error", "error message");
          console.error("ERROR----");
          console.error(err);
        });
    },
  });

  return (
    <>
      <Stack sx={{mb: 4}} spacing={1}>
        <Typography variant="h5">{t("auth.login")}</Typography>
        <Typography color="text.secondary" variant="body2">
          {t("auth.noAccount")}&nbsp;
          <Link href="/auth/register" underline="hover" variant="subtitle2">
            {t("auth.register")}
          </Link>
        </Typography>
      </Stack>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          <TextField
            autoFocus
            error={!!(formik.touched.email && formik.errors.email)}
            fullWidth
            helperText={formik.touched.email && formik.errors.email}
            label={t("auth.emailAddress")}
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="email"
            value={formik.values.email}
          />
          <TextField
            error={!!(formik.touched.password && formik.errors.password)}
            fullWidth
            helperText={formik.touched.password && formik.errors.password}
            label={t("auth.password")}
            name="password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="password"
            value={formik.values.password}
          />
        </Stack>
        <Button
          fullWidth
          disabled={formik.isSubmitting}
          sx={{mt: 3}}
          size="large"
          type="submit"
          variant="contained"
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : t("auth.login")}
        </Button>
        <Box sx={{mt: 3}}>
          <Link href="/auth/reset-password" underline="hover" variant="subtitle2">
            {t("auth.forgotPassword")}
          </Link>
        </Box>
      </form>
    </>
  );
};
