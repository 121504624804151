import {Stack} from "@mui/system";
import Typography from "@mui/material/Typography";
import {FormControlLabel, ListItem, Switch, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {useParams} from "react-router-dom";
import {actionService} from "src/services/action";
import {SplashScreen} from "src/components/splash-screen";

export const UserNotification = () => {
  const {notificationToken} = useParams();
  const [notificationData, setNotificationData] = useState(null);
  const [initialNotificationData, setInitialNotificationData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    actionService.getNotifications(notificationToken).then(data => {
      setNotificationData(data);
      setInitialNotificationData(data);
    });
  }, []);

  if (notificationData === null) {
    return <SplashScreen positionFixed={true} />;
  }

  const handleNotification = (field, value) => {
    setNotificationData(prevData => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = _ => {
    setLoading(true);
    actionService.updateNotifications(notificationToken, notificationData).then(_ => {
      setLoading(false);
      setInitialNotificationData(notificationData);
    });
  };

  return (
    <>
      <Stack sx={{mb: 4}} spacing={1}>
        <Typography variant="h5">Notification</Typography>
      </Stack>
      <ListItem disableGutters disablePadding sx={{mt: 0, pt: 0, mb: 1}}>
        <FormControlLabel
          control={
            <Switch
              checked={notificationData.email_notification_enabled}
              onClick={() =>
                handleNotification(
                  "email_notification_enabled",
                  !notificationData.email_notification_enabled,
                )
              }
              color="primary"
            />
          }
          label={"Email Notifications"}
        />
      </ListItem>
      <ListItem disableGutters disablePadding sx={{mt: 0, pt: 0, mb: 1}}>
        <FormControlLabel
          control={
            <Switch
              checked={notificationData.sms_notification_enabled}
              onClick={() =>
                handleNotification(
                  "sms_notification_enabled",
                  !notificationData.sms_notification_enabled,
                )
              }
              color="primary"
            />
          }
          label={"SMS Notifications"}
        />
      </ListItem>
      <Button
        disabled={
          initialNotificationData.email_notification_enabled ===
            notificationData.email_notification_enabled &&
          initialNotificationData.sms_notification_enabled ===
            notificationData.sms_notification_enabled
        }
        fullWidth
        sx={{mt: 3}}
        size="large"
        type="submit"
        variant="contained"
        onClick={handleSubmit}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : "Submit"}
      </Button>
    </>
  );
};
