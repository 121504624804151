import React, { useState } from 'react';
import { Container } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export const AccountInfo = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isEditing, setIsEditing] = useState(false); // Track if the form is in edit mode
  const [gender, setGender] = useState('');
  const [province, setProvince] = useState('');
  const [country, setCountry] = useState('Türkiye');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [iban, setIban] = useState('');
  const [ibanConfirmation, setIbanConfirmation] = useState('');
  const [ibanError, setIbanError] = useState('');
  const [open, setOpen] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    if (event.target.value !== "Türkiye") {
      setProvince(''); // Clear province if country is not Turkey
    }
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);

    // Regular expression to match the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(value)) {
      setEmailError(''); // Clear any previous error
    } else {
      setEmailError('Please enter a valid email address.');
    }
  };

  const formatPhoneNumber = (value) => {
    // Remove all non-digit characters
    const digits = value.replace(/\D/g, '');

    // Format the phone number
    let formattedNumber = '';
    if (digits.length > 0) {
      formattedNumber = digits.substring(0, 4); // First 4 digits
    }
    if (digits.length > 4) {
      formattedNumber += ' ' + digits.substring(4, 7); // Next 3 digits
    }
    if (digits.length > 7) {
      formattedNumber += ' ' + digits.substring(7, 9); // Next 2 digits
    }
    if (digits.length > 9) {
      formattedNumber += ' ' + digits.substring(9, 11); // Last 2 digits
    }

    return formattedNumber;
  };

  const handlePhoneNumberChange = (event) => {
    const value = event.target.value;
    const formattedValue = formatPhoneNumber(value);

    // Regular expression to match the phone number format
    const phoneRegex = /^05\d{2} \d{3} \d{2} \d{2}$/;

    if (phoneRegex.test(formattedValue)) {
      setPhoneError(''); // Clear any previous error
    } else {
      setPhoneError('Please enter a valid phone number in the format 0532 052 52 82.');
    }

    setPhoneNumber(formattedValue);
  };

  const handleIbanChange = (event) => {
    const value = event.target.value;
    setIban(value);
    // Check if the IBAN and confirmation match
    if (value !== ibanConfirmation) {
      setIbanError("IBANs do not match.");
    } else {
      setIbanError('');
    }
  };

  const handleIbanConfirmationChange = (event) => {
    const value = event.target.value;
    setIbanConfirmation(value);
    // Check if the IBAN and confirmation match
    if (value !== iban) {
      setIbanError("IBANs do not match.");
    } else {
      setIbanError('');
    }
  };

  const handleOpenDeleteDialog = () => {
    setOpen(true);
  };

  const handleOpenPasswordDialog = () => {
    setOpenPassword(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpen(false);
  };

  const handleClosePasswordDialog = () => {
    setOpenPassword(false);
  };

  const handleDeleteAccount = () => {
    console.log("Account deleted");
    setOpen(false);
  };

  const handleChangePassword = () => {
    console.log("Password change request sent");
    setOpenPassword(false);
  };

  const toggleEditMode = () => {
    if (isEditing) {
      // Here you would typically save the data to a server
      console.log("Saving data...");
    }
    setIsEditing(!isEditing);
  };

  const provinces = [
    { code: "01", name: "Adana" },
    { code: "02", name: "Adıyaman" },
    { code: "03", name: "Afyonkarahisar" },
    { code: "04", name: "Ağrı" },
    { code: "05", name: "Amasya" },
    { code: "06", name: "Ankara" },
    { code: "07", name: "Antalya" },
    { code: "08", name: "Artvin" },
    { code: "09", name: "Aydın" },
    { code: "10", name: "Balıkesir" },
    { code: "11", name: "Bilecik" },
    { code: "12", name: "Bingöl" },
    { code: "13", name: "Bitlis" },
    { code: "14", name: "Bolu" },
    { code: "15", name: "Burdur" },
    { code: "16", name: "Bursa" },
    { code: "17", name: "Çanakkale" },
    { code: "18", name: "Çankırı" },
    { code: "19", name: "Çorum" },
    { code: "20", name: "Denizli" },
    { code: "21", name: "Diyarbakır" },
    { code: "22", name: "Edirne" },
    { code: "23", name: "Elazığ" },
    { code: "24", name: "Erzincan" },
    { code: "25", name: "Erzurum" },
    { code: "26", name: "Eskişehir" },
    { code: "27", name: "Gaziantep" },
    { code: "28", name: "Giresun" },
    { code: "29", name: "Gümüşhane" },
    { code: "30", name: "Hakkari" },
    { code: "31", name: "Hatay" },
    { code: "32", name: "Isparta" },
    { code: "33", name: "Mersin" },
    { code: "34", name: "İstanbul" },
    { code: "35", name: "İzmir" },
    { code: "36", name: "Kars" },
    { code: "37", name: "Kastamonu" },
    { code: "38", name: "Kayseri" },
    { code: "39", name: "Kırklareli" },
    { code: "40", name: "Kırşehir" },
    { code: "41", name: "Kocaeli" },
    { code: "42", name: "Konya" },
    { code: "43", name: "Kütahya" },
    { code: "44", name: "Malatya" },
    { code: "45", name: "Manisa" },
    { code: "46", name: "Kahramanmaraş" },
    { code: "47", name: "Mardin" },
    { code: "48", name: "Muğla" },
    { code: "49", name: "Muş" },
    { code: "50", name: "Nevşehir" },
    { code: "51", name: "Niğde" },
    { code: "52", name: "Ordu" },
    { code: "53", name: "Rize" },
    { code: "54", name: "Sakarya" },
    { code: "55", name: "Samsun" },
    { code: "56", name: "Siirt" },
    { code: "57", name: "Sinop" },
    { code: "58", name: "Sivas" },
    { code: "59", name: "Tekirdağ" },
    { code: "60", name: "Tokat" },
    { code: "61", name: "Trabzon" },
    { code: "62", name: "Tunceli" },
    { code: "63", name: "Şanlıurfa" },
    { code: "64", name: "Uşak" },
    { code: "65", name: "Van" },
    { code: "66", name: "Yozgat" },
    { code: "67", name: "Zonguldak" },
    { code: "68", name: "Aksaray" },
    { code: "69", name: "Bayburt" },
    { code: "70", name: "Karaman" },
    { code: "71", name: "Kırıkkale" },
    { code: "72", name: "Batman" },
    { code: "73", name: "Şırnak" },
    { code: "74", name: "Bartın" },
    { code: "75", name: "Ardahan" },
    { code: "76", name: "Iğdır" },
    { code: "77", name: "Yalova" },
    { code: "78", name: "Karabük" },
    { code: "79", name: "Kilis" },
    { code: "80", name: "Osmaniye" },
    { code: "81", name: "Düzce" },
  ];

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Typography variant="h5" gutterBottom>
          Edit Info
        </Typography>
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            mb: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: 3,
              justifyContent: 'space-between',
            }}
          >
            <TextField
              sx={{ width: isMobile ? '100%' : '50%' }} 
              label="Email (info@galataanket.com)"
              variant="outlined"
              value={email}
              onChange={handleEmailChange}
              error={!!emailError}
              helperText={emailError}
              disabled={!isEditing}
            />
            <TextField
              sx={{ width: isMobile ? '100%' : '50%' }} 
              label="Phone number (0532 000 00 00)"
              variant="outlined"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              error={!!phoneError}
              helperText={phoneError}
              disabled={!isEditing}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: 3,
              justifyContent: 'space-between',
            }}
          >
            <FormControl sx={{ width: isMobile ? '100%' : '50%' }}  variant="outlined" disabled={!isEditing}>
              <InputLabel>Gender</InputLabel>
              <Select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                label="Gender"
              >
                <MenuItem value=""><em>None</em></MenuItem>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
            <TextField
              sx={{ width: isMobile ? '100%' : '50%' }} 
              label="Birthday"
              variant="outlined"
              type="date" // Set input type to date
              InputLabelProps={{ shrink: true }} // Ensure label shrinks to fit date input
              defaultValue="2001-01-01" // Set default value in YYYY-MM-DD format
              disabled={!isEditing}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: 3,
              justifyContent: 'space-between',
            }}
          >
            <TextField
              sx={{ width: isMobile ? '100%' : '50%' }} 
              label="Country"
              variant="outlined"
              value={country}
              onChange={handleCountryChange}
              disabled={!isEditing}
            />
            {country === "Türkiye" && (
              <FormControl sx={{ width: isMobile ? '100%' : '50%' }}  variant="outlined" disabled={!isEditing}>
                <InputLabel>Province</InputLabel>
                <Select
                  value={province}
                  onChange={(e) => setProvince(e.target.value)}
                  label="Province"
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  {provinces.map((province) => (
                    <MenuItem key={province.code} value={province.name}>
                      {province.code} - {province.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>

          <Typography variant="body2" sx={{ mt: 2 }}>
            Banka hesabına para gönderebilmek için aşağıdaki bilgilerin doldurulması gerekmektedir.
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: 3,
              justifyContent: 'space-between',
            }}
          >
            <TextField
              sx={{ width: isMobile ? '100%' : '50%' }} 
              label="Fullname"
              variant="outlined"
              defaultValue="John doe"
              disabled={!isEditing}
            />
            <TextField
              sx={{ width: isMobile ? '100%' : '50%' }} 
              label="Bank Name"
              variant="outlined"
              defaultValue="Bank Name"
              disabled={!isEditing}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: 3,
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}>
            <TextField
              sx={{ width: isMobile ? '100%' : '50%' }} 
              label=" Iban (TR XX XXXX XXXX XXXX XXXX XXXX)"
              variant="outlined"
              value={iban}
              onChange={handleIbanChange}
              error={!!ibanError}
              helperText={ibanError}
              disabled={!isEditing}
            />
            <TextField
              sx={{ width: isMobile ? '100%' : '50%' }} 
              label="Iban confirmation (TR XX XXXX XXXX XXXX XXXX XXXX)"
              variant="outlined"
              value={ibanConfirmation}
              onChange={handleIbanConfirmationChange}
              error={!!ibanError}
              helperText={ibanError}
              disabled={!isEditing}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 3,
            }}
          >
            <Button
              variant="contained"
              sx={{ backgroundColor: "#ad1445", width: isMobile ? '100%' : '50%' }}
              onClick={toggleEditMode}
            >
              {isEditing ? "Save Account Information" : "Edit Account Information"}
            </Button>
          </Box>
        </Box>

        {/* Change Password */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 1,
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2, // Adds space between the buttons
          }}
        >
          <Button 
            variant="contained" 
            color="error" 
            sx={{ backgroundColor: "#ad1445", mt: 3, width: isMobile ? '100%' : '50%' }} 
            onClick={handleOpenPasswordDialog}
           
          >
            Change Password
          </Button>

          {/* Change Password Dialog */}
          <Dialog
            open={openPassword}
            onClose={handleClosePasswordDialog}
          >
            <DialogTitle>{"Are you sure you want to change your password?"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Changing your password will require you to log in again. Are you sure you want to proceed?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosePasswordDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleChangePassword} color="error" autoFocus>
                Change Password
              </Button>
            </DialogActions>
          </Dialog>

          <Button 
            variant="contained" 
            color="error" 
            sx={{ backgroundColor: "#ad1445", mt: 3, width: isMobile ? '100%' : '50%' }} 
            onClick={() => window.location.href = "/auth/login"}
          >
            Change Communication Preferences
          </Button>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2, // Adds space between the buttons
            mt: 3,
          }}
        >
          {/* Delete Account */}
          <Button 
            variant="outlined" 
            color="error" 
            sx={{ mt: 3, width: isMobile ? '100%' : '50%' }} 
            onClick={handleOpenDeleteDialog}
          >
            Delete Account
          </Button>

          {/* Delete Account Dialog */}
          <Dialog
            open={open}
            onClose={handleCloseDeleteDialog}
          >
            <DialogTitle>{"Are you sure you want to delete your account?"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Deleting your account is irreversible. All your data will be permanently removed. 
                Are you sure you want to proceed?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDeleteAccount} color="error" autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </Container>
  );
};
