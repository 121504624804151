import React from 'react';
import { Container } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export const CompletedSurveys = () => {

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Completed Surveys
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Survey Name</TableCell>
                <TableCell>Completed Date</TableCell>
                <TableCell>Reward Type</TableCell>
                <TableCell>Survey Status</TableCell>
                <TableCell>Reward Status</TableCell>
                <TableCell>Reward Access</TableCell>
                <TableCell>Reward Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Tüketim alışkanlıkları</TableCell>
                <TableCell>***</TableCell>
                <TableCell>Çekiliş</TableCell>
                <TableCell>Ongoing</TableCell>
                <TableCell>Waiting Lottery</TableCell>
                <TableCell>100</TableCell>
                <TableCell>100</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tüketim alışkanlıkları</TableCell>
                <TableCell>***</TableCell>
                <TableCell>Anket Soruları</TableCell>
                <TableCell>Ongoing</TableCell>
                <TableCell>Waiting End of Survey</TableCell>
                <TableCell>Not Earned</TableCell>
                <TableCell>100</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tüketim alışkanlıkları</TableCell>
                <TableCell>***</TableCell>
                <TableCell>Çekiliş</TableCell>
                <TableCell>Completed</TableCell>
                <TableCell>Done - Lottery</TableCell>
                <TableCell>Earned - check rewards section</TableCell>
                <TableCell>100</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tüketim alışkanlıkları</TableCell>
                <TableCell>***</TableCell>
                <TableCell>Anket Soruları</TableCell>
                <TableCell>Completed</TableCell>
                <TableCell>Done - Survey</TableCell>
                <TableCell>Not Earned</TableCell>
                <TableCell>100</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};
