import {createContext, useState} from "react";

const initialState = {
  alert: {
    open: false,
    type: null,
    message: null,
  },
};

export const SettingsContext = createContext({
  ...initialState,
});

export const SettingsProvider = props => {
  const {children} = props;
  const [alert, setAlert] = useState(initialState.alert);

  const showAlert = (type, message) => {
    setAlert({
      open: true,
      type,
      message,
    });
  };

  const closeAlert = () => {
    setAlert(initialState.alert);
  };

  return (
    <SettingsContext.Provider
      value={{
        alert,
        showAlert,
        closeAlert,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;
