import React, { useState } from 'react';
import { Container } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export const Rewards = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [rewardRequests, setRewardRequests] = useState([]);
  const [ibanAmount, setIbanAmount] = useState('');
  const [availablePoints, setAvailablePoints] = useState(250); // Initial available points
  const [usedPoints, setUsedPoints] = useState(0); // Initial used points

  const handleReceiveGift = (rewardType, amount) => {
    const pointsToUse = parseInt(amount) || 0;

    if (pointsToUse > 0 && pointsToUse <= availablePoints) {
      const newRequest = {
        date: new Date().toLocaleDateString(),
        surveyName: 'Media Anketi', // This could be dynamic based on the reward or context
        status: 'Pending',
        pointsUsed: pointsToUse,
        rewardType: rewardType,
        sentDate: new Date().toLocaleDateString(),
        giftCode: Math.floor(Math.random() * 100000).toString(), // Simulate a gift code generation
      };

      setRewardRequests([...rewardRequests, newRequest]);
      setAvailablePoints(availablePoints - pointsToUse);
      setUsedPoints(usedPoints + pointsToUse);
    } else {
      alert('Invalid points value or insufficient available points.');
    }
  };

  const handleIbanChange = (event) => {
    setIbanAmount(event.target.value);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        {/* Points Boxes */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: 4,
          }}
        >
          <Box
            sx={{
              backgroundColor: '#ffffff',
              padding: 2,
              borderRadius: 2,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              flex: 1,
              mx: 1,
            }}
          >
            <Typography variant="h6" color="textSecondary">
              Available Points
            </Typography>
            <Typography variant="h4" sx={{ mt: 1 }}>
              {availablePoints}
            </Typography>
          </Box>

          <Box
            sx={{
              backgroundColor: '#ffffff',
              padding: 2,
              borderRadius: 2,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              flex: 1,
              mx: 1,
            }}
          >
            <Typography variant="h6" color="textSecondary">
              Used Points
            </Typography>
            <Typography variant="h4" sx={{ mt: 1 }}>
              {usedPoints}
            </Typography>
          </Box>
        </Box>

        {/* Rewards Section */}
        <Typography variant="h5" sx={{ mb: 2 }}>
          Rewards
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            flexWrap: 'wrap',
            justifyContent: isMobile ? 'center' : 'space-between',
            gap: 2,
            mb: 4,
          }}
        >
          {/* Reward Cards */}
          <Box
            sx={{
              backgroundColor: '#ffffff',
              padding: 2,
              borderRadius: 2,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              width: isMobile ? '100%' : '24%',
              mx: isMobile ? 0 : 1,
              mb: isMobile ? 2 : 0,
            }}
          >
            <Typography variant="h6">Migros Hediye Kartı</Typography>
            <Typography variant="body1" color="primary">
              Miktar: 100
            </Typography>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#ad1445", mt: 2 }}
              onClick={() => handleReceiveGift('Migros Hediye Kartı', 100)}
              disabled={availablePoints < 100}
            >
              Receive Gifts
            </Button>
          </Box>

          <Box
            sx={{
              backgroundColor: '#ffffff',
              padding: 2,
              borderRadius: 2,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              width: isMobile ? '100%' : '24%',
              mx: isMobile ? 0 : 1,
              mb: isMobile ? 2 : 0,
            }}
          >
            <Typography variant="h6">Migros Hediye Kartı</Typography>
            <Typography variant="body1" color="primary">
              Miktar: 200
            </Typography>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#ad1445", mt: 2 }}
              onClick={() => handleReceiveGift('Migros Hediye Kartı', 200)}
              disabled={availablePoints < 200}
            >
              Receive Gifts
            </Button>
          </Box>

          <Box
            sx={{
              backgroundColor: '#ffffff',
              padding: 2,
              borderRadius: 2,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              width: isMobile ? '100%' : '24%',
              mx: isMobile ? 0 : 1,
              mb: isMobile ? 2 : 0,
            }}
          >
            <Typography variant="h6">IBAN'a Gönder</Typography>
            <Typography variant="body1" color="primary">
              Miktar:
              <TextField
                sx={{ ml: 1, width: '50%' }}
                size="small"
                variant="outlined"
                value={ibanAmount}
                onChange={handleIbanChange}
              />
            </Typography>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#ad1445", mt: 2 }}
              onClick={() => handleReceiveGift("IBAN'a Gönder", ibanAmount)}
              disabled={!ibanAmount || availablePoints < parseInt(ibanAmount)}
            >
              Receive Gifts
            </Button>
          </Box>

          <Box
            sx={{
              backgroundColor: '#ffffff',
              padding: 2,
              borderRadius: 2,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              width: isMobile ? '100%' : '24%',
              mx: isMobile ? 0 : 1,
              mb: isMobile ? 2 : 0,
            }}
          >
            <Typography variant="h6">A101 Hediye Kartı</Typography>
            <Typography variant="body1" color="primary">
              Miktar: 100
            </Typography>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#ad1445", mt: 2 }}
              onClick={() => handleReceiveGift('A101 Hediye Kartı', 100)}
              disabled={availablePoints < 100}
            >
              Receive Gifts
            </Button>
          </Box>
        </Box>

        {/* Previous Reward Requests Section */}
        <Typography variant="h6" sx={{ mb: 2 }}>
          Previous reward requests
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Requested Date</TableCell>
                <TableCell>Survey Name</TableCell>
                <TableCell>Requested Status</TableCell>
                <TableCell>Point Used</TableCell>
                <TableCell>Reward Type</TableCell>
                <TableCell>Reward Sent Date</TableCell>
                <TableCell>Gift Code</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rewardRequests.length > 0 ? (
                rewardRequests.map((request, index) => (
                  <TableRow key={index}>
                    <TableCell>{request.date}</TableCell>
                    <TableCell>{request.surveyName}</TableCell>
                    <TableCell>{request.status}</TableCell>
                    <TableCell>{request.pointsUsed}</TableCell>
                    <TableCell>{request.rewardType}</TableCell>
                    <TableCell>{request.sentDate}</TableCell>
                    <TableCell>{request.giftCode}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No reward requests submitted yet.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};
