import React from 'react';
import { Container } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export const OpenSurveys = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        {/* Summary Boxes */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: isMobile ? 'column' : 'row',
            mb: 4,
            gap: isMobile ? 2 : 0,
          }}
        >
          <Box
            sx={{
              backgroundColor: '#ffffff',
              padding: 2,
              borderRadius: 2,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              flex: 1,
              mx: 1,
            }}
          >
            <Typography variant="h6" color="textSecondary">
              My Completed Survey
            </Typography>
            <Typography variant="h4" sx={{ mt: 1 }}>
              1245
            </Typography>
          </Box>

          <Box
            sx={{
              backgroundColor: '#ffffff',
              padding: 2,
              borderRadius: 2,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
              flex: 1,
              mx: 1,
            }}
          >
            <Typography variant="h6" color="textSecondary">
              Total Rewards Received
            </Typography>
            <Typography variant="h4" sx={{ mt: 1 }}>
              1245
            </Typography>
          </Box>
        </Box>

        {/* Open Surveys Section */}
        <Typography variant="h5" sx={{ mb: 2 }}>
          Open Surveys
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: isMobile ? 'center' : 'left',
            gap: 2,
            mb: 4,
          }}
        >
          {/* Survey Cards */}
          <Card sx={{ width: isMobile ? '100%' : '24%', minHeight: '150px' }}>
            <CardContent>
              <Typography variant="h6">Medya Anketi 1</Typography>
              <Typography variant="body2">Duration:</Typography>
              <Typography variant="body2">Reward Point:</Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  mt: 2,
                }}
              >
                Start
              </Button>
            </CardContent>
          </Card>

          <Card sx={{ width: isMobile ? '100%' : '24%', minHeight: '150px' }}>
            <CardContent>
              <Typography variant="h6">Medya Anketi 2</Typography>
              <Typography variant="body2">Duration:</Typography>
              <Typography variant="body2">Reward Point:</Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  mt: 2,
                }}
              >
                Start
              </Button>
            </CardContent>
          </Card>
        </Box>

        {/* Expired Surveys Section */}
        <Typography variant="h5" sx={{ mb: 2 }}>
          Expired Surveys
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: isMobile ? 'center' : 'left',
            gap: 2,
            mb: 4,
          }}
        >
          {/* Survey Cards */}
          <Card sx={{ width: isMobile ? '100%' : '24%', minHeight: '150px' }}>
            <CardContent>
              <Typography variant="h6">Askerlik 2</Typography>
              <Typography variant="body2">Duration:</Typography>
              <Typography variant="body2">Reward Point:</Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  mt: 2,
                }}
              >
                Start
              </Button>
            </CardContent>
          </Card>

          <Card sx={{ width: isMobile ? '100%' : '24%', minHeight: '150px' }}>
            <CardContent>
              <Typography variant="h6">Ekonomi</Typography>
              <Typography variant="body2">Duration:</Typography>
              <Typography variant="body2">Reward Point:</Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  mt: 2,
                }}
              >
                Start
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Container>
  );
};
