import Error404 from "src/pages/404";
import {authRoutes} from "src/routes/auth";
import {actionRoutes} from "src/routes/action";
import {dashboardRoutes} from "src/routes/dashboard";

export const routes = [
  ...authRoutes,
  ...actionRoutes,
  ...dashboardRoutes,
  {
    path: "*",
    element: <Error404 />,
  },
];
