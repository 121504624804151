export const paths = {
  index: "/",
  auth: {
    login: "/auth/login",
    register: "/auth/register",
  },
  dashboard: {
    openSurveys: "/dashboard/open-surveys",
    completedSurveys: "/dashboard/completed-surveys",
    rewards: "/dashboard/rewards",
    accountInfo: "/dashboard/accountinfo",
  },
};
